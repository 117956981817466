import Vue from 'vue'
import Axios from 'axios'
import { HTTP_ERROR_MESSAGES } from '../../constant/data'
import Snackbar from '@/components/Snackbar'

const getDefaultState = () => {
  return {
    relationshipsType: [],
    entityTypeAndSgaID: [],
    countryList: [],
    isLoading: false,
    isCountryListLoading: false,
    relationshipData: [],
    relationshipHierarchy: [],
    isLoadingSgaID: false,
    isLoadingImmediateParent: false,
    isLoadingUltimateParent: false,
    isLoadingLinkRequestId: false,
    sgaIdData: [],
    immediateParentData: [],
    ultimateParentData: [],
    linkRequestData: [],

    limit: 10,
    offset: 0,
    searchQueryWithin: '',
    existingEntityData: [],
    totalRecords: 0,
    isLoadingExistingEntityGrid: false,
    sort: null,
    order: null,

    isLoadingDuplicateEntityGrid: false,
    duplicateEntityData: [],

    failedToFetchFlag: false
  }
}
const cancelTokens = {
  sgaId: null,
  immediateParent: null,
  ultimateParent: null,
  linkRequestId: null
}

// defined outside to keep the global scope
let existingEntityCancelToken

export default {
  namespaced: true,
  state: getDefaultState(),
  actions: {
    async addNewEntity({ commit, dispatch }, payload) {
      commit('SET_LOADING', true)
      try {
        const response = await Vue.prototype.$http.post(
          '/dmp/common/add-entity',
          payload
        )
        if (
          response.data &&
          response.data.status === 200 &&
          response.data.data
        ) {
          commit('SET_2052A_DETAILS', response.data.data)
          return response.data.status
        }
      } catch (error) {
        throw error
      } finally {
        commit('SET_LOADING', false)
      }
    },

    async getCountryList({ commit }) {
      commit('SET_COUNTRY_LIST_LOADING', true)
      commit('SET_LOADING', true)

      try {
        const response = await Vue.prototype.$http.get('/dmp/address/country')
        if (
          response.data &&
          response.data.status === 200 &&
          response.data.data
        ) {
          commit('SET_COUNTRY_LIST', response.data.data)
        }
      } catch (error) {
        throw error
      } finally {
        commit('SET_COUNTRY_LIST_LOADING', false)
        commit('SET_LOADING', false)
      }
    },

    async getRelationships({ commit }, data) {
      commit('SET_LOADING', true)
      commit('SET_FAILED_TO_FETCH_FLAG', false)
      try {
        const response = await Vue.prototype.$http2.get('/dmp/relation', {
          params: data
        })
        if (response?.data && response.data?.status === 200) {
          if (response.data?.data) {
            if (data.hierarchyTree) {
              commit('SET_RELATIONSHIP_HIERARCHY', response.data.data)
            } else {
              commit('SET_RELATIONSHIP_GRID', response.data.data)
            }
          }
        } else {
          if (data.hierarchyTree) {
            commit('SET_RELATIONSHIP_HIERARCHY', [])
          } else {
            commit('SET_RELATIONSHIP_GRID', [])
          }
        }
      } catch (error) {
        commit('SET_FAILED_TO_FETCH_FLAG', true)
        commit('SET_RELATIONSHIP_HIERARCHY', [])
        commit('SET_RELATIONSHIP_GRID', [])
        throw new Error(error)
      } finally {
        commit('SET_LOADING', false)
      }
    },

    async addRelationships({ commit }, relationshipObj) {
      try {
        const response = await Vue.prototype.$http.post(
          '/dmp/relationship/',
          relationshipObj.data,
          {
            params: {
              sgaId: relationshipObj.sgaId
            }
          }
        )

        if (response?.data && response.data?.status === 200) {
          if (response.data?.data) {
            commit('SET_RELATIONSHIP_GRID', [response.data.data])
          }
        }
        // Returned response for status of submission
        return response?.data
      } catch (error) {
        throw new Error(error)
      }
    },

    async deleteRelationships({ commit }, relationshipId) {
      try {
        const response = await Vue.prototype.$http.delete('/dmp/relation', {
          params: { relationshipId }
        })
        // Returned response status for Snackbar
        return response?.data?.status
      } catch (error) {
        throw new Error(error)
      }
    },

    async getRelationshipsType({ commit }) {
      commit('SET_RELATIONSHIP_TYPE', [])
      try {
        const response = await Vue.prototype.$http.get(
          '/dmp/relationship/type',
          {}
        )
        if (response?.data && response.data?.status === 200) {
          if (response.data?.data) {
            commit('SET_RELATIONSHIP_TYPE', response.data.data)
          }
        }
      } catch (error) {
        throw new Error(error)
      }
    },

    async getDataForEntityDropdown({ commit, state }, entityObj) {
      const { field, searchQuery } = entityObj
      // Set loading flag based on field
      let loadingFlag = ''
      switch (field) {
        case 'sgaId':
          loadingFlag = 'isLoadingSgaID'
          break
        case 'immediateParent':
          loadingFlag = 'isLoadingImmediateParent'
          break
        case 'ultimateParent':
          loadingFlag = 'isLoadingUltimateParent'
          break
        case 'linkRequestId':
          loadingFlag = 'isLoadingLinkRequestId'
          break
        default:
          break
      }
      // Set loading flag to true
      if (loadingFlag) {
        commit('SET_ENTITY_LOADING', { field: loadingFlag, value: true })
      }
      // Cancel the previous request if there is one
      if (cancelTokens[field]) {
        commit('SET_ENTITY_LOADING', { field: loadingFlag, value: true })
        cancelTokens[field]('Request canceled due to new request.')
      }
      try {
        // Create a new cancel token
        const source = Axios.CancelToken.source()
        cancelTokens[field] = source.cancel
        const response = await Vue.prototype.$http.get(
          '/dmp/relationship/entity',
          {
            params: {
              searchQuery
            },
            cancelToken: source.token
          }
        )
        if (
          response?.data &&
          response.data.status === 200 &&
          response.data.data
        ) {
          if (field) {
            switch (field) {
              case 'sgaId':
                commit('SET_SGAID_DATA', response.data.data)
                break
              case 'immediateParent':
                commit('SET_IMMEDIATEPARENT_DATA', response.data.data)
                break
              case 'ultimateParent':
                commit('SET_ULTIMATEPARENT_DATA', response.data.data)
                break
              case 'linkRequestId':
                commit('SET_LINKREQUEST_DATA', response.data.data)
                break
              default:
                break
            }
          } else {
            // Store data for each state
            commit('SET_SGAID_DATA', response.data.data)
            commit('SET_IMMEDIATEPARENT_DATA', response.data.data)
            commit('SET_ULTIMATEPARENT_DATA', response.data.data)
            commit('SET_LINKREQUEST_DATA', response.data.data)
          }
        }
        commit('SET_ENTITY_LOADING', { field: loadingFlag, value: false })
      } catch (error) {
        if (!Axios.isCancel(error)) {
          throw new Error(error)
        } else {
          // Handle cancellation
        }
      }
    },
    async getExistingEntity({ commit, state }, searchQuery) {
      // Check if there are any previous pending requests
      if (typeof existingEntityCancelToken !== typeof undefined) {
        existingEntityCancelToken.cancel(HTTP_ERROR_MESSAGES.REQUEST_CANCELLED)
      }

      // Save the cancel token for the current request
      existingEntityCancelToken = Axios.CancelToken.source()
      try {
        commit('SET_EXISTING_ENTITY_LOADER', true)
        const requestPayload = {
          searchQuery: searchQuery,
          limit: state.limit || 10,
          offset: state.offset || 0,
          searchQueryWithin: state.searchQueryWithin || '',
          sort: state.sort || '',
          order: state.order || ''
        }

        const response = await Vue.prototype.$http.post(
          '/dmp/relationship/existing-entity',
          requestPayload,
          {
            cancelToken: existingEntityCancelToken.token
          }
        )

        if (
          response.data &&
          response.data.status === 200 &&
          response.data.data
        ) {
          commit('SET_EXISTING_ENTITY_DATA', response.data.data)
        } else if (
          response.data.status === 204 ||
          response.data.status === 404
        ) {
          commit('SET_EXISTING_ENTITY_DATA', [])
        }

        // Common Pagination Set Logic
        const paginationData = {
          totalRows: parseInt(response.data.totalRows) || 0
        }
        commit('SET_EXISTING_ENTITY_COUNT', paginationData)
        commit('SET_EXISTING_ENTITY_LOADER', false)
      } catch (error) {
        if (Axios.isCancel(error)) {
          console.log('Request canceled', error.message)
        } else {
          throw error
        }
      }
    },

    async getDuplicateEntity({ commit, state }, payload) {
      try {
        commit('SET_DUPLICATE_ENTITY_LOADER', true)
        const requestPayload = {
          entityName: payload.entityName,
          domCountry: payload.domcontryCode
        }

        const response = await Vue.prototype.$http.post(
          '/dmp/relationship/exact-match-entity',
          requestPayload
        )

        commit('SET_DUPLICATE_ENTITY_DATA', response.data?.data ?? [])
        commit('SET_DUPLICATE_ENTITY_LOADER', false)

        if (response.data && response.data.status === 404) {
          commit('SET_DUPLICATE_ENTITY_DATA', [])
        }

        commit('SET_DUPLICATE_ENTITY_LOADER', false)
      } catch (error) {
        throw error
      }
    },
    setLimitAndOffset({ commit }, { limit = 10, offset = 0 } = {}) {
      commit('SET_LIMIT_AND_OFFSET', { limit, offset })
    },

    setSortAndOrder({ commit }, { sort = '', order = '' } = {}) {
      commit('SET_SORT_AND_ORDER', { sort, order })
    },

    updateSearchQueryWithin({ commit }, searchQueryWithin) {
      commit('SET_SEARCH_QUERY_WITHIN', searchQueryWithin)
    },

    resetPaginationData({ commit }) {
      commit('SET_LIMIT_AND_OFFSET', { limit: 10, offset: 0 })
      commit('SET_SORT_AND_ORDER', { sort: '', order: '' })
    },

    setExistingEntityLoader({ commit }, isLoading) {
      commit('SET_EXISTING_ENTITY_LOADER', isLoading)
    },

    resetExistingGridData({ commit }) {
      commit('SET_SEARCH_QUERY_WITHIN', '')
      commit('SET_EXISTING_ENTITY_DATA', [])
      commit('SET_LIMIT_AND_OFFSET', { limit: 10, offset: 0 })
      commit('SET_SORT_AND_ORDER', { sort: '', order: '' })
      commit('SET_EXISTING_ENTITY_COUNT', 0)
    },

    async resetRelationshipsDetails({ commit }) {
      commit('SET_RELATIONSHIP_GRID', [])
    },

    async updateHierarchyView({ commit }, data) {
      commit('UPDATE_HEIRARCHY_VIEW', data)
    },

    async updateDataForSgaID({ commit }) {
      commit('SET_SGAID_DATA', [])
    },

    async updateDataForImmediateParent({ commit }) {
      commit('SET_IMMEDIATEPARENT_DATA', [])
    },

    async updateDataForUltimateParent({ commit }) {
      commit('SET_ULTIMATEPARENT_DATA', [])
    },

    async updateLinkRequestData({ commit }) {
      commit('SET_LINKREQUEST_DATA', [])
    }
  },

  mutations: {
    SET_COUNTRY_LIST(state, countryList) {
      state.countryList = countryList.map((countryObj) => {
        // Assign new key

        countryObj.value = countryObj.id
        countryObj.text = `${countryObj.countryCode}-${countryObj.name}`

        // Delete old key
        delete countryObj.id
        delete countryObj.name

        return countryObj
      })
    },

    SET_LOADING(state, isLoading) {
      state.isLoading = isLoading
    },

    SET_ENTITY_LOADING(state, payload) {
      state[payload.field] = payload.value
    },

    SET_COUNTRY_LIST_LOADING(state, isLoading) {
      state.isCountryListLoading = isLoading
    },

    UPDATE_HEIRARCHY_VIEW(state, data) {
      state.relationshipHierarchy = data
    },

    SET_RELATIONSHIP_GRID(state, relationshipData) {
      // Define the flattenTree function
      let gridData = []
      const orphanNodes = []

      const prepareNewNodes = () => {
        relationshipData.forEach((item) => {
          if (item?.items) {
            findAllNestedChild(item)
          } else {
            orphanNodes.push(item)
          }
        })
      }

      const findAllNestedChild = (itemData) => {
        if (!itemData?.items) {
          return itemData
        } else {
          const lastChild = findAllNestedChild({ ...itemData.items[0] })
          if (lastChild) {
            gridData.push(lastChild)
          }
          gridData.push(itemData) // here we can find json by reltaionshipId from input json so that unused child can be removed
        }
      }

      prepareNewNodes()
      gridData = [...gridData, ...orphanNodes]

      // Flatten the tree-like structure into a flat array
      const flatRelationshipData = gridData

      // Set the state with the flattened data
      state.relationshipData = [...flatRelationshipData]
    },
    SET_FAILED_TO_FETCH_FLAG(state, isFlag) {
      state.failedToFetchFlag = isFlag
    },

    SET_RELATIONSHIP_HIERARCHY(state, relationshipData) {
      state.relationshipHierarchy = relationshipData
    },

    SET_RELATIONSHIP_TYPE(state, relationshipsType) {
      state.relationshipsType = relationshipsType
    },

    SET_SGAID_DATA(state, sgaIdData) {
      state.sgaIdData = sgaIdData
      if (state.sgaIdData && state.sgaIdData.length) {
        state.sgaIdData.forEach((data) => {
          const fields = ['sgaId', 'requestId', 'entityName', 'countryCode']
          data.text = fields
            .filter(
              (field) => data[field] !== undefined && data[field] !== null
            )
            .map((field) => data[field])
            .join('-')
        })
      }
    },

    SET_IMMEDIATEPARENT_DATA(state, immediateParentData) {
      state.immediateParentData = immediateParentData
      if (state.immediateParentData && state.immediateParentData.length) {
        state.immediateParentData.forEach((data) => {
          const fields = ['sgaId', 'requestId', 'entityName', 'countryCode']
          data.text = fields
            .filter(
              (field) => data[field] !== undefined && data[field] !== null
            )
            .map((field) => data[field])
            .join('-')
        })
      }
    },

    SET_ULTIMATEPARENT_DATA(state, ultimateParentData) {
      state.ultimateParentData = ultimateParentData
      if (state.ultimateParentData && state.ultimateParentData.length) {
        state.ultimateParentData.forEach((data) => {
          const fields = ['sgaId', 'requestId', 'entityName', 'countryCode']
          data.text = fields
            .filter(
              (field) => data[field] !== undefined && data[field] !== null
            )
            .map((field) => data[field])
            .join('-')
        })
      }
    },

    SET_LINKREQUEST_DATA(state, linkRequestData) {
      state.linkRequestData = linkRequestData
      if (state.linkRequestData && state.linkRequestData.length) {
        state.linkRequestData.forEach((data) => {
          const fields = ['sgaId', 'requestId', 'entityName', 'countryCode']
          data.text = fields
            .filter(
              (field) => data[field] !== undefined && data[field] !== null
            )
            .map((field) => data[field])
            .join('-')
        })
      }
    },

    SET_EXISTING_ENTITY_DATA(state, existingEntityData) {
      state.existingEntityData = existingEntityData
    },

    SET_EXISTING_ENTITY_COUNT(state, paginationData) {
      state.totalRecords = paginationData.totalRows
    },

    SET_LIMIT_AND_OFFSET(state, { limit = 10, offset = 0 } = {}) {
      state.limit = limit
      state.offset = offset
    },

    SET_SORT_AND_ORDER(state, { sort = '', order = '' } = {}) {
      state.sort = sort
      state.order = order
    },

    SET_SEARCH_QUERY_WITHIN(state, searchQueryWithin) {
      state.searchQueryWithin = searchQueryWithin
    },

    SET_EXISTING_ENTITY_LOADER(state, isLoading) {
      state.isLoadingExistingEntityGrid = isLoading
    },

    SET_DUPLICATE_ENTITY_LOADER(state, isLoading) {
      state.isLoadingDuplicateEntityGrid = isLoading
    },

    SET_DUPLICATE_ENTITY_DATA(state, duplicateEntityData) {
      state.duplicateEntityData = duplicateEntityData
    }
  }
}
