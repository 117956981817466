import Vue from 'vue'
import Snackbar from '@/components/Snackbar'
import store from '../../store/index'
import Axios from 'axios'

const getDefaultState = () => {
  return {
    pmtDocumentsData: [],
    pmtDocumentTypeData: [],
    docCategoryList: [],
    companyList: [],
    s3UrlPath: '',
    pmtDocumentsFilter: {
      totalCount: 0,
      searchContent: null,
      offset: 0,
      limit: 10,
      filterOptions: null
    },
    companyFilter: [],
    searchCompanyDocuments: [],
    filteredColumnList: [],
    activeButton: 'button1'
  }
}

// defined outside to keep the global scope
let cancelToken

export default {
  namespaced: true,
  state: getDefaultState(),
  actions: {
    downloadPmtDocumentsData({ commit }, reqData) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post('/pmt/documents/download-csv', reqData)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    generateZip({ commit }, reqData) {
      let payload = {}
      payload.urls = reqData
      return new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post('/pmt/documents/generate-zip', payload, {
            responseType: 'blob'
          })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getPmtDocumentsList({ commit }, reqData) {
      commit('LOADING', true)
      if (typeof cancelToken !== typeof undefined) {
        cancelToken.cancel('Request canceled due to new request.')
      }
      cancelToken = Axios.CancelToken.source()
      Vue.prototype.$http
        .post('/pmt/documents', reqData, { cancelToken: cancelToken.token })
        .then((response) => {
          if (response.data.status === 200) {
            if (response.data && response.data.data) {
              commit('SET_PMT_DOCUMENTS_DATA', response.data.data)
              commit('SET_ROW_COUNT', response.data.totalRows)
            }
          }
          if (response.data.status === 204) {
            commit('SET_ROW_COUNT', response.data.totalRows)
            commit('SET_PMT_DOCUMENTS_DATA', [])
          }
          commit('LOADING', false)
        })
        .catch((e) => {
          if (e.message === 'Request canceled due to new request.') {
            commit('LOADING', true)
          } else {
            commit('LOADING', false)
          }
        })
    },
    getDocumentTypeList({ commit, state }) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post('/pmt/documents/document-type')
          .then((response) => {
            if (response.data.status === 200) {
              if (response.data && response.data.data) {
                commit('SET_PMT_DOCUMENT_TYPE_DATA', response.data.data)
                resolve()
              }
            }
            if (response.data.status === 204) {
              resolve()
            }
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    uploadFileToS3({ commit }, reqData) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post('/pmt/documents/upload-to-s3', reqData, {
            cancelToken: cancelToken.token
          })
          .then((response) => {
            if (response.data.status === 200) {
              if (
                response.data &&
                response.data.data &&
                response.data.data.path
              ) {
                commit('SET_S3_URL_PATH', response.data.data.path)
                resolve(response.data.data.path)
              }
            }
            if (response.data.status === 204) {
              resolve()
            }
          })
          .catch((error) => {
            Snackbar({
              message: error,
              type: 'is-error'
            })
            reject(error)
          })
      })
    },
    deleteFileFromS3({ commit, state }, pathData) {
      let message = 'Document deleted successfully'
      let title = 'is-success'

      try {
        const response = Vue.prototype.$http.post(
          `/pmt/documents/delete-from-s3`,
          pathData
        )
        const data = response.data
        if (data) {
          message = 'Document deleted successfully'
          title = 'is-success'
        } else {
          message = 'Something went wrong!'
          title = 'is-danger'
        }
      } catch (error) {
        message = error.message || 'Something went wrong!'
        title = 'is-danger'
      }

      Snackbar({
        message: message,
        type: title
      })
    },
    deleteFileFromS3({ commit }, path) {
      const reqData = { filePath: path }
      return new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post('/pmt/documents/delete-from-s3', reqData, {
            cancelToken: cancelToken.token
          })
          .then((response) => {
            if (response.data.status === 200) {
              if (
                response.data &&
                response.data.data &&
                response.data.data.path
              ) {
                commit('SET_S3_URL_PATH', response.data.data.path)
                resolve(response.data.data.path)
              }
            }
            if (response.data.status === 204) {
              resolve()
            }
          })
          .catch((error) => {
            Snackbar({
              message: error,
              type: 'is-error'
            })
            reject(error)
          })
      })
    },
    /** This method returns a list of document categories present in the database */
    getDocumentCategoryList({ commit }, reqData) {
      let body = { searchText: reqData }
      return new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post(`/pmt/documents/document-type`, body)
          .then((response) => {
            if (response.data.status === 200 && response.data.data) {
              commit('DOCUMENT_CATEGORY_LIST', response.data.data)
              resolve(response.data.data)
            }
          })
      })
    },
    updatePmtDocumentsFilter({ commit }, pmtDocumentsFilter) {
      localStorage.setItem(
        'PMT_DOCUMENTS_FILTERS',
        JSON.stringify(pmtDocumentsFilter)
      )
      commit('SET_DOCUMENTS_SEARCH_CONTENT', pmtDocumentsFilter?.searchContent)
      commit('SET_DOCUMENTS_SKIP', pmtDocumentsFilter?.offset)
      commit('SET_DOCUMENTS_TAKE', pmtDocumentsFilter?.limit)
      commit('SET_FILTER_OPTIONS', pmtDocumentsFilter?.filterOptions)
    },
    updateCompanyFilter({ commit }, companyFilter) {
      localStorage.setItem('COMPANY_FILTER', JSON.stringify(companyFilter))
      commit('SET_COMPANY_FILTER', companyFilter)
    },
    updateSearchCompanyDocuments({ commit }, searchCompanyDocuments) {
      commit('SEARCH_COMPANY_DOCUMENTS', searchCompanyDocuments)
    },
    addFilteredColumnList({ commit, state }, columnObj) {
      const { columnName, isFilter } = columnObj
      let columnList = [...state.filteredColumnList]
      if (isFilter) {
        columnList = [...columnList, columnName]
      } else {
        const index = columnList.indexOf(columnName)
        if (index > -1) {
          columnList.splice(index, 1)
        }
      }
      columnList = [...new Set(columnList)]
      localStorage.setItem(
        'DOCUMENTS_FILTERED_COLUMNS',
        JSON.stringify(columnList)
      )
      commit('SET_FILTERED_COLUMN_LIST', columnList)
    },
    updateFilteredColumnList({ commit }, filteredColumnList) {
      commit('SET_FILTERED_COLUMN_LIST', filteredColumnList)
    },
    async downloadDocument({ commit, state }, documentData) {
      let message = 'Downloaded the document successfully'
      let title = 'is-success'

      try {
        const requestData = { key: documentData.key }
        if (requestData.key) {
          const response = await Vue.prototype.$http.post(
            `/pmt/documents/download`,
            requestData
          )
          const url = response.data.data.url
          if (url) {
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', documentData.key)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          } else {
            message = 'Document not found in response!'
            title = 'is-danger'
          }
        } else {
          message = 'Document key is missing!'
          title = 'is-danger'
        }
      } catch (error) {
        message = error.message || 'Something went wrong!'
        title = 'is-danger'
      }

      Snackbar({
        message: message,
        type: title
      })
    },
    async handleDocTypeAPI({ commit, state }, docTypeData) {
      commit('LOADING', true)
      let message = 'Document updated successfully'
      let title = 'is-success'
      try {
        const requestData = { data: docTypeData }
        if (requestData.data) {
          const response = await Vue.prototype.$http.put(
            `/pmt/documents/update-document-type`,
            requestData
          )
          const result = response.data
          if (result) {
            title = 'is-success'
          } else {
            message = 'Document not found in response!'
            title = 'is-danger'
          }
        } else {
          message = 'Document key is missing!'
        }
        commit('LOADING', false)
      } catch (error) {
        message = error.message || 'Something went wrong!'
        title = 'is-danger'
        commit('LOADING', false)
      }
      Snackbar({
        message: message,
        type: title
      })
    },
    async handlePublishedDateChange({ commit, state }, pubDateData) {
      commit('LOADING', true)
      let message = 'Published Date updated successfully'
      let title = 'is-success'
      try {
        const requestData = { data: pubDateData }
        if (requestData.data) {
          const response = await Vue.prototype.$http.put(
            `/pmt/documents/update-publication-date`,
            requestData
          )
          const result = response.data
          if (result) {
            title = 'is-success'
          } else {
            message = 'Document not found in response!'
            title = 'is-danger'
          }
        } else {
          message = 'Document key is missing!'
        }
        commit('LOADING', false)
      } catch (error) {
        message = error.message || 'Something went wrong!'
        title = 'is-danger'
        commit('LOADING', false)
      }
      Snackbar({
        message: message,
        type: title
      })
    },
    addNewDocument({ commit, state }, documentData) {
      commit('LOADING', false)
      let message = 'Document Added successfully'
      let title = 'is-success'

      return new Promise((resolve, reject) => {
        const requestData = { data: documentData }

        if (!requestData.data) {
          message = 'Document key is missing!'
          title = 'is-danger'
          Snackbar({ message, type: title })
          commit('LOADING', false)
          reject(new Error(message))
          return
        }

        Vue.prototype.$http
          .post(`/pmt/documents/add-document`, requestData, {
            cancelToken: cancelToken.token
          })
          .then((response) => {
            const result = response.data

            if (result.data) {
              title = 'is-success'
              resolve(true)
            } else {
              message = result.info
              title = 'is-danger'
              resolve(false)
            }
          })
          .catch((error) => {
            message = error.message || 'Something went wrong!'
            title = 'is-danger'
            resolve(false)
          })
          .finally(() => {
            commit('LOADING', false)
            Snackbar({ message, type: title })
          })
      })
    },
    getCompanyList({ commit }, value) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get(`/pmt/documents/company?searchText=${value}`)
          .then((response) => {
            if (response.data.status === 200 && response.data.data) {
              commit('COMPANY_LIST', response.data.data.data)
              resolve(response.data.data.data)
            }
          })
      })
    },
  },
  mutations: {
    LOADING(state, payload) {
      store.state.filters.loading = payload
    },
    SET_ROW_COUNT(state, totalRows) {
      state.pmtDocumentsFilter.totalCount = totalRows
    },
    SET_PMT_DOCUMENTS_DATA(state, pmtDocumentsData) {
      const { calcAmt, calcuatedCurrencyName, origAmt, originalCurrencyName } =
        pmtDocumentsData
      const newPmtDocumentsData = pmtDocumentsData.map((element) => {

        return {
          ...element,
          pub_date: new Date(element.pub_date),
          calcAmtCombined: calcAmt + calcuatedCurrencyName,
          origAmtCombined: origAmt + originalCurrencyName
        }
      })
      state.pmtDocumentsData = newPmtDocumentsData
    },
    SET_PMT_DOCUMENT_TYPE_DATA(state, pmtDocumentTypeData) {
      state.pmtDocumentTypeData = pmtDocumentTypeData
    },
    SET_DOCUMENTS_SEARCH_CONTENT(state, searchContent) {
      state.pmtDocumentsFilter.searchContent = searchContent
    },
    SET_DOCUMENTS_SKIP(state, offset) {
      state.pmtDocumentsFilter.offset = offset
    },
    SET_COMPANY_FILTER(state, companyFilter) {
      state.companyFilter = companyFilter
    },
    SET_DOCUMENTS_TAKE(state, limit) {
      state.pmtDocumentsFilter.limit = limit
    },
    SET_FILTER_OPTIONS(state, filterOptions) {
      state.pmtDocumentsFilter.filterOptions = filterOptions
    },
    SET_FILTERED_COLUMN_LIST(state, filteredColumnList) {
      state.filteredColumnList = filteredColumnList
    },
    SEARCH_COMPANY_DOCUMENTS(state, companyDisclosure) {
      state.searchCompanyDocuments = companyDisclosure
    },
    setActiveButton(state, payload) {
      state.activeButton = payload
    },
    DOCUMENT_CATEGORY_LIST(state, payload) {
      state.docCategoryList = payload
    },
    COMPANY_LIST(state, payload) {
      state.companyList = payload
    },
    SET_S3_URL_PATH(state, payload) {
      state.s3UrlPath = payload
    }
  }
}
